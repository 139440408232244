<template>
    <div ref="append-article">
        <zw-sidebar @shown="shown" :title="$t('common.select.offering')">
            <div v-if="!loading" class="px-3 py-2">
                <validation-observer tag="div" ref="observer">
                    <b-row>
                        <b-col>
                            <zw-table ref="table"
                                      tableName="sales"
                                      columnsPrefix="common.column.sale."
                                      base-table="offering"
                                      :customSource="getThis()"
                                      :items="myProvider"
                                      :sticky="false"
                                      sticky-header="100%"
                                      :fields="fields"
                                      :filter="filter"
                                      :selected="selected"
                                      :actions-list="getActions()"
                            >
                                <template #cell(total)="row">
                                    <b-button size="sm" @click="select(row.item.id)" variant="success">
                                        {{ $t('common.button.select') }}
                                    </b-button>
                                </template>
                            </zw-table>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12" class="text-xs-center">
                            <b-button :disabled="selected.length!=1" block @click="select()" variant="primary">
                                {{ $t('common.button.select') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </validation-observer>
            </div>
        </zw-sidebar>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import tableDateFilter from "@/bundles/erika_common_bundle/mixins/table-date-filter";

export default {
    name: 'SelectOfferingModal',
    mixins: [commonSave, commonTable, tableDateFilter],
    data() {
        return {
            tableUniqKey: 'select-offering-modal',
            loading: true,
            payload: {},
            callback: null,
            saveFilters: false,
        }
    },
    methods: {
        ...mapGetters('Sales', ['getSalesTable']),
        shown() {
            this.$set(this, 'selected', [])
            this.loading = false
        },
        select(id = null) {
            if (id == null) {
                id = this.selected[0].id
            }

            this.callback(id)

            this.$root.$children[0].closeModal(this.$children[0].$refs['sidebar'])
        },
        getThis() {
            return this
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Sales/fetchSales', 'getSalesTable');
        },
        getActions() {
            return []
        }
    },
}
</script>